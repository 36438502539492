<template>
  <div>
    <p class="text-2xl mb-6">CA consolidé / site</p>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col cols="12">
        <ca-table></ca-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Filters from "@/components/finance/filters/Filters";
import CaTable from "@/components/finance/tables/CaTable";

export default {
  name: "SiteConsolidatedCa",
  components: {
    CaTable,
    Filters,
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
